import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01c49ed2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-layout-footer" }
const _hoisted_2 = { class: "app-layout-footer-global" }
const _hoisted_3 = { class: "footer-copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.copyright), 1)
    ])
  ]))
}