
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { random } from 'lodash-es';
import { ElMessage } from 'element-plus';
import { filefuns } from 'cx-utils';

import { uploadFile } from '@/api/open';
import {
  getFileBase64,
} from '@/modules/task-manage/design-task/api/index';
import { TFileData } from '@/components/upload/package/type.d';
import { CHANNEL_LIST, CHANNEL_ENUM } from '@/constant/global';
import { useCommonStore } from '@/store/common';
import { trackingClick } from '@/utils/tracking';

const uploadLimit = {
  size: 10,
  accept: 'image/png,image/jpeg,image/jpg',
};

export default defineComponent({
  setup() {
    const commonState = useCommonStore();
    const $router = useRouter();
    const visible = ref(false);
    const valueUrl = ref('');

    // 跳转搜索结果页
    const handleSearch = (url: string) => {
      trackingClick({
        eventName: '导航栏-以图搜图',
      });
      const newPage = $router.resolve({
        name: 'SearchImageList',
        query: {
          url: encodeURIComponent(url),
          channelId: commonState.searchImageChannelId,
        },
      });
      window.open(newPage.href);
      visible.value = false;
      valueUrl.value = '';
    };

    // 上传图片
    const handleUpload = (imgs: TFileData[]) => {
      if (imgs && imgs.length > 0) {
        handleSearch(imgs[0].url);
      }
    };

    // 显示上传框
    const handleShowUpload = () => {
      visible.value = true;
    };

    // 输入
    const handleInput = (v: string) => {
      valueUrl.value = v;
    };

    // 粘贴图片
    const handlePaste = async (e: ClipboardEvent) => {
      let file = null;
      const items = e.clipboardData?.items;
      if (items && items.length) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            file = items[i].getAsFile();
            break;
          }
        }
      }
      if (!file) {
        console.log('粘贴的不是图片');
        return;
      }
      // 上传限制20m
      if (file.size / 1024 / 1024 > uploadLimit.size) {
        ElMessage.warning(`上传图片大小不能超过 ${uploadLimit.size}MB!`);
        return;
      }
      // 检查类型
      if (!uploadLimit.accept.split(',').includes(file.type)) {
        ElMessage.warning(`上传文件格式不符合${uploadLimit.accept}`);
        return;
      }
      const imgBlob = await filefuns.compressImg(
        file,
        (w) => {
          if (w > 356) {
            return 356 / w;
          }
          return 1;
        },
      );
      const newFile = new window.File([imgBlob], file.name, { type: file.type });
      try {
        const formData = new FormData();
        formData.append('files', newFile, file.name);
        const { data } = await uploadFile(formData);
        handleSearch(data[0].url);
      } catch (error) {
        console.log(error);
      }
    };

    // 校验是否有效图片链接
    const checkImageUrl = (url: string) => {
      const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
      if (reg.test(url)) {
        const regImg = /\.(png|jpg|jpeg)$/;
        if (regImg.test(url)) {
          return false;
        }
      }
      return true;
    };

    // 将图片处理为base64后上传oss
    const handleConfirm = async () => {
      if (checkImageUrl(valueUrl.value)) {
        ElMessage.warning('请输入正确的图片网址');
        return;
      }
      try {
        const res = await getFileBase64({
          url: valueUrl.value,
        });
        const arr: any = res.data.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const type = mime.split('/')[1];
        if (!uploadLimit.accept.split(',').includes(mime)) {
          ElMessage.warning(`文件格式不符合${uploadLimit.accept}`);
          return;
        }
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        // eslint-disable-next-line no-plusplus
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const formData = new FormData();
        const file = new File([u8arr], `${Date.now()}_${random(1, 9999)}.${type}`, { type: mime });
        formData.append('files', file, file.name);
        const { data } = await uploadFile(formData);
        handleSearch(data[0].url);
      } catch (error) {
        console.log(error);
      }
    };

    const handleChange = (v: CHANNEL_ENUM) => {
      commonState.updateSearchImageChannelId(v);
    };

    onMounted(() => {
      document.addEventListener('click', () => {
        if (visible.value) {
          visible.value = false;
        }
      });
      const searchEl = document.getElementById('searchImage');
      searchEl?.addEventListener('paste', e => handlePaste(e));
    });

    return {
      CHANNEL_LIST,
      commonState,
      visible,
      valueUrl,
      handleUpload,
      handleShowUpload,
      handleInput,
      handleConfirm,
      handleChange,
    };
  },
});

