import { computed } from 'vue';
import { useAccountStore } from '@/store/account';
import { useRouter } from 'vue-router';

export default function useHeader() {
  const accountStore = useAccountStore();
  const currentRouter = useRouter();

  const accountData = computed(() => {
    return accountStore.account;
  });
  /**
     * 退出登录
     */
  const handleLogout = async () => {
    await accountStore.logout();
    currentRouter.replace('/login');
  };

  return {
    accountStore,
    accountData,
    handleLogout,
  };
}
