
import { defineComponent } from 'vue';

const YEAR = new Date().getFullYear();

export default defineComponent({
  name: 'AppFooter',
  props: {
    copyright: {
      type: String,
      default: `Copyright © ${YEAR} 广州致衣信息科技服务有限公司`,
    },
  },
});
