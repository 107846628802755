
import { defineComponent } from 'vue';
import AppFooter from './components/app-footer/index.vue';
import AppHeader from './components/app-header/index.vue';

export default defineComponent({
  components: {
    AppFooter,
    AppHeader,
  },
  setup() {
    return {
    };
  },
});
