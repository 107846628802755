import { defineStore } from 'pinia';
import { CHANNEL_ENUM } from '@/constant/global';
import { IState } from './types';

export const useCommonStore = defineStore({
  id: 'common',
  state: (): IState => ({
    searchImageChannelId: CHANNEL_ENUM.TAOPIN,
  }),
  actions: {
    updateSearchImageChannelId(id: CHANNEL_ENUM) {
      const ids = Object.values(CHANNEL_ENUM);
      if (id && ids.includes(id)) {
        this.searchImageChannelId = id;
      }
    },
  },
});
